import Vue from 'vue';
import Vuex from 'vuex';

import router from '@/router';
import api from '@/utils/api.js';
import Token from '@/utils/token.js';
import dayjs from 'dayjs';

Vue.use(Vuex);

const key = 'consultant-token';

export default {
  namespaced: true,
  state: {
    user: {},
    reservations: [],
    authy_id: null,
    token: Token.getSync(key),
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setReservations(state, reservations) {
      state.reservations = reservations;
    },
    loginRequest(state, { authy_id }) {
      state.authy_id = authy_id;
    },
    loginVerifyRequest(state, { access_token }) {
      Token.set(key, access_token);
      state.token = access_token;
    },
    logoutRequest(state) {
      Token.remove(key);
      state.user = {};
      state.token = null;

      router.push({
        name: 'ConsultantLogin',
      });
    },
  },
  actions: {
    async login({ commit }, payload) {
      const response = await api.post('consultant/login', payload);
      commit('loginRequest', response.data);
    },
    async loginVerify({ commit, dispatch }, payload) {
      const response = await api.post('consultant/login/verify', payload);
      commit('loginVerifyRequest', response.data);
      await dispatch('getAuthUser');
    },
    async logout({ commit }) {
      commit('logoutRequest');
    },
    async getAuthUser({ commit }) {
      try {
        const response = await api.get('consultant');
        commit('setUser', response.data);
      } catch (e) {
        commit('logoutRequest');
      }
    },

    async getReservations({ commit }) {
      const response = await api.get('consultant/reservations');
      const reservations = response.data.map((reservation) => {

        return {
          ...reservation,
          date: dayjs(reservation.date).format('YYYY-MM-DD HH:mm'),
          end_date: dayjs(reservation.end_date).format('YYYY-MM-DD HH:mm'),
        }
      });

      commit('setReservations', reservations);
    }
  },
  getters: {
    isLoggedIn(state) {
      return !!state.token;
    },
    initials(state) {
      return (state.user.name || '').split(' ')
        .map((name) => name.charAt(0))
        .slice(0, 2)
        .join('')
        .toUpperCase();
    },
  },
  modules: {},
}
