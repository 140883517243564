const emptyConsultant = () => {
  return {
    name: '',
    email: '',
    phone: '',
    avatar: '',
    welcome_message: '',
    duration: 20,
    gap: 5,
    availability: {
      monday: [],
      tuesday: [],
      saturday: [],
      thursday: [],
      wednesday: [],
      friday: [],
      sunday: []
    }
  };
}

export default {
  namespaced: true,
  state: () => ({
    openModal: false,
    currentPeriod: '',
    form: emptyConsultant()
  }),
  mutations: {
    resetData(state) {
      state.form = emptyConsultant();
    },
    setData(state, form) {
      state.form = form;
    },
    toggleModal(state, status) {
      state.openModal = status;
    },
    removePeriod(state, period) {
      state.form.availability = state.form.availability.filter((curPeriod) => {
        return curPeriod.dayWeek !== period.dayWeek && curPeriod.startPeriod !== period.startPeriod && curPeriod.endPeriod !== period.endPeriod;
      });
    },
    addPeriod(state) {
      state.form.availability.push({
        dayWeek: state.currentPeriod,
        startPeriod: '',
        endPeriod: '',
      });
    },
  },
  actions: {
    openModal({ commit }) {
      commit('toggleModal', true);
    },
    closeModal({ commit }) {
      commit('toggleModal', false);
    },
    addPeriod({ commit }) {
      commit('addPeriod');
    },
    removePeriod({ commit }, index) {
      commit('removePeriod', index);
    },
    set({ commit }, payload) {
      commit('setData', payload);
    },
    reset({ commit }) {
      commit('resetData');
    },
  },
  getters: {
    currentPeriods(state) {
      return state.form.availability.filter((period) => {
        return period.dayWeek === state.currentPeriod;
      }) || [];
    },
    getByPeriod: (state) => (periodName) => {
      return state.form.availability.filter((period) => {
        return period.dayWeek === periodName;
      }) || [];
    },
  }
}
