import Vue from "vue";
import VueRouter from "vue-router";
import store from '@/store/index';

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Admin",
    redirect: { name: 'Dashboard' },
    // route level code-splitting
    // this generates a separate chunk (dashboard.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "dashboard" */ "../views/admin/Dashboard.vue"),
    meta: { role: 'admin' }
  },
  {
    path: "/login",
    name: "AdminLogin",
    component: () => import(/* webpackChunkName: "adminLogin" */ "../views/admin/Login.vue"),
    meta: { role: 'admin' }
  },
  {
    path: "/forgot-password",
    name: "AdminForgotPassword",
    component: () => import(/* webpackChunkName: "AdminForgotPassword" */ "../views/admin/ForgotPassword.vue"),
    meta: { role: 'admin' }
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () => import(/* webpackChunkName: "ResetPassword" */ "../views/admin/ResetPassword.vue"),
    meta: { role: 'admin' }
  },
  {
    path: "/admin/dashboard",
    name: "Dashboard",
    component: () => import(/* webpackChunkName: "dashboard" */ "../views/admin/Dashboard.vue"),
    meta: { requiresAuth: true, role: 'admin' }
  },
  {
    path: "/admin/historic",
    name: "Historic",
    component: () => import(/* webpackChunkName: "historic" */ "../views/admin/Historic.vue"),
    meta: { requiresAuth: true, role: 'admin' }
  },
  {
    path: "/admin/users-rating",
    name: "UsersRating",
    component: () => import(/* webpackChunkName: "usersRating" */ "../views/admin/UsersRating.vue"),
    meta: { requiresAuth: true, role: 'admin' }
  },
  {
    path: "/admin/users",
    name: "Users",
    component: () => import(/* webpackChunkName: "users" */ "../views/admin/Users.vue"),
    meta: { requiresAuth: true, role: 'admin' }
  },
  {
    path: "/consultant/users",
    name: "ConsultantUsers",
    component: () => import(/* webpackChunkName: "users" */ "../views/consultant/UsersConsultant.vue"),
    meta: { requiresAuth: true, role: 'consultant' }
  },
  {
    path: "/admin/products",
    name: "Products",
    component: () => import(/* webpackChunkName: "products" */ "../views/admin/Products.vue"),
    meta: { requiresAuth: true, role: 'admin' }
  },
  {
    path: "/admin/admins",
    name: "Admins",
    component: () => import(/* webpackChunkName: "admins" */ "../views/admin/admins/Admins.vue"),
    meta: { requiresAuth: true, role: 'admin' }
  },
  {
    path: "/admin/admins/register-admin",
    name: "RegisterAdmin",
    component: () => import(/* webpackChunkName: "registerAdmin" */ "../views/admin/admins/RegisterAdmin.vue"),
    meta: { requiresAuth: true, role: 'admin' }
  },
  {
    path: "/admin/admins/edit-admin/:id",
    name: "EditAdmin",
    component: () => import(/* webpackChunkName: "editAdmin" */ "../views/admin/admins/EditAdmin.vue"),
    meta: { requiresAuth: true, role: 'admin' }
  },
  
  {
    path: "/admin/consultants",
    name: "Consultants",
    component: () => import(/* webpackChunkName: "consultants" */ "../views/admin/consultants/Consultants.vue"),
    meta: { requiresAuth: true, role: 'admin' }
  },
  {
    path: "/admin/consultants/:action/:id?",
    name: "RegisterConsultant",
    component: () => import(/* webpackChunkName: "registerConsultant" */ "../views/admin/consultants/RegisterConsultant.vue"),
    meta: { requiresAuth: true, role: 'admin' }
  },
  {
    path: "/consultant",
    name: "Consultant",
    redirect: { name: 'Calendar' },
    component: () => import(/* webpackChunkName: "consultant" */ "../views/consultant/Consultant.vue"),
    children: [
      {
        path: "/consultant/login",
        name: "ConsultantLogin",
        // route level code-splitting
        // this generates a separate chunk (consultantLogin.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "consultantLogin" */ "../views/consultant/Login.vue"),
        meta: { role: 'consultant' }
      },
      {
        path: "/consultant/calendar",
        name: "Calendar",
        // route level code-splitting
        // this generates a separate chunk (calendar.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "calendar" */ "../views/consultant/Calendar.vue"),
        meta: { requiresAuth: true, role: 'consultant' }
      },
      {
        path: "/consultant/video/:room",
        name: "Video",
        // route level code-splitting
        // this generates a separate chunk (call.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "video" */ "../views/consultant/Video.vue"),
        meta: { requiresAuth: true, role: 'consultant' }
      },
    ]
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const role = to.matched.some((record) => record.meta.role === 'admin') ? 'admin' : 'consultant';
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresGuest = to.matched.some((record) => record.meta.guest);

  const admin = {
    defaultRoute: 'Admin',
    loginRoute: 'AdminLogin',
    isLoggedIn: store.getters['admin/isLoggedIn'],
    user: store.state.admin.user,
    getUser: 'admin/getAuthUser'
  };

  const consultant = {
    defaultRoute: 'Consultant',
    loginRoute: 'ConsultantLogin',
    isLoggedIn: store.getters['consultant/isLoggedIn'],
    user: store.state.consultant.user,
    getUser: 'consultant/getAuthUser'
  };

  const roleMeta = role === 'consultant' ? consultant : admin;

  const loginRoute = {
    name: roleMeta.loginRoute,
    params: { redirect: to.fullPath },
    query: { q: to.query.q },
  };

  if (requiresAuth && !roleMeta.isLoggedIn) {
    next(loginRoute);
  } else if (requiresGuest && roleMeta.isLoggedIn) {
    next(roleMeta.defaultRoute);
  } else if (requiresAuth && roleMeta.isLoggedIn) {
    if (!roleMeta.user.id) {
      await store.dispatch(roleMeta.getUser);
    }

    next();
  } else {
    next();
  }
});

export default router;
