<template>
  <div class="avatar w-10 h-10" @click="$emit('click', $event)"
     :style="{
      backgroundImage: `url(${image})`
    }"
  >
    <template v-if="!image">{{ initials }}</template>
  </div>
</template>

<script>
export default {
  name: 'Avatar',
  props: ['image', 'name'],
  computed: {
    initials() {
      return (this.name || '').split(' ')
        .map((name) => name.charAt(0))
        .slice(0, 2)
        .join('')
        .toUpperCase();
    },
  }
};
</script>

<style lang="scss">
.avatar {
  @apply relative flex items-center flex-shrink-0 justify-center text-sm font-bold bg-blue bg-no-repeat bg-cover text-white rounded-full leading-loose shadow-lg;
}
</style>
