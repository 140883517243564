import Vue from "vue";
import VueMask from "v-mask";
import anime from 'animejs';
import vuetify from "@/plugins/vuetify";

import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isoWeek from 'dayjs/plugin/isoWeek';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import 'dayjs/locale/pt-br.js';

import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import VueNoty from 'vuejs-noty';

import VueTimepicker from 'vue2-timepicker';

import {
  ValidationProvider,
  ValidationObserver,
  localize,
  extend,
} from 'vee-validate';

import { required, email, min_value, max_value, confirmed } from "vee-validate/dist/rules";

import ptBR from "vee-validate/dist/locale/pt_BR.json";

import 'vuejs-noty/dist/vuejs-noty.css';
import 'vue2-timepicker/dist/VueTimepicker.css';
import "@/assets/css/index.css";

dayjs.locale('pt-br');
dayjs.extend(isoWeek);
dayjs.extend(isSameOrAfter);
dayjs.extend(dayOfYear);

Vue.config.productionTip = false;

Vue.use(VueMask);
Vue.use(VueTimepicker);

Vue.use(VueNoty, {
  layout: 'bottom',
  theme: 'nivea',
  maxVisible: 1,
  animation: {
    open(promise) {
      anime({
        targets: this.barDom,
        translateY: [250, 0],
        easing: 'easeInOutExpo',
        complete() {
          promise((resolve) => {
            resolve();
          });
        },
      });
    },
    close(promise) {
      anime({
        targets: this.barDom,
        translateY: [0, 100],
        opacity: [1, 0],
        easing: 'easeInOutExpo',
        complete() {
          promise((resolve) => {
            resolve();
          });
        },
      });
    },
  },
});

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
localize("pt_BR", ptBR);

extend("phone", {
  validate(value) {
    const phone = value.replace(/[^0-9]/g, "");
    return phone.length === 11;
  },
  message: "Celular inválido",
});

extend("required", required);

extend("email", {
  ...email,
  message: "E-mail inválido",
});

extend('min_value', {
  ...min_value,
  message: 'Campo precisa ser maior que {min}.',
});

extend('max_value', {
  ...max_value,
  message: 'Campo não pode ser maior que {max}.',
});

extend("confirmed", {
  ...confirmed,
  message: "As senhas não correspondem",
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
