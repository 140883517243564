const set = (key, value) => new Promise((resolve) => {
  localStorage.setItem(key, value);

  resolve(true);
});

const get = (key) => new Promise((resolve) => {
  const value = localStorage.getItem(key);

  resolve(value);
});

const getSync = (key) => localStorage.getItem(key);

const remove = (key) => new Promise((resolve) => {
  localStorage.removeItem(key);

  resolve(true);
});

export default {
  set,
  get,
  getSync,
  remove,
};
