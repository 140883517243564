import router from '@/router';
import api from '@/utils/api.js';
import Token from '@/utils/token.js';

const key = 'admin-token';

export default {
  namespaced: true,
  state: () => ({
    user: {},
    token: Token.getSync(key),
  }),
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    loginRequest(state, { access_token }) {
      Token.set(key, access_token);
      state.token = access_token;
    },
    logoutRequest(state) {
      Token.remove(key);
      state.user = {};
      state.token = null;

      router.push({
        name: 'AdminLogin',
      });
    },
  },
  actions: {
    async login({ commit, dispatch }, payload) {
      const response = await api.post('admin/login', payload);
      commit('loginRequest', response.data);
      await dispatch('getAuthUser');
    },
    async logout({ commit }) {
      commit('logoutRequest');
    },
    async getAuthUser({ commit }) {
      try {
        const response = await api.get('admin');
        commit('setUser', response.data);
      } catch (e) {
        commit('logoutRequest');
      }
    },
  },
  getters: {
    isLoggedIn(state) {
      return !!state.token;
    },
  }
}
