var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app bg-cream min-h-screen",class:{
    'has-user-modal': _vm.hasUserModal,
    'has-cancel-call-modal' : _vm.hasCancelCallModal,
    'has-end-call-modal' : _vm.hasEndCallModal,
    'has-schedule-modal' : _vm.openModal,
    'has-calendar-filter-modal' : _vm.hasCalendarFilter,
    'has-delete-register-modal' : _vm.hasDeleteRegisterModal,
    'has-csv-modal' : _vm.hasCSVModal
  },attrs:{"id":"app"}},[_c('Menu'),_c('router-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }