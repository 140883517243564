export default {
  namespaced: true,
  state: () => ({
    open: false,
  }),
  mutations: {
    toggleModal(state, currentState) {
      state.open = currentState;
    },
  },
  actions: {
    openModal({commit}) {
      commit('toggleModal', true);
    },
    closeModal({commit}) {
      commit('toggleModal', false);
    },
  },
}
