import router from '@/router';
import Token from '@/utils/token';
import axios from 'axios';

const basedomain = process.env.VUE_APP_API_URL;
export const baseURL = `${basedomain}`;

const getRole = () => {
  const routeIsConsultant = router.currentRoute.path.startsWith('/consultant');
  const locationIsConsultant = location.pathname.startsWith('/consultant');

  if (router.currentRoute.meta.role) {
    return router.currentRoute.meta.role;
  }

  return (locationIsConsultant || routeIsConsultant) ? 'consultant' : 'admin';
}

const middleware = async (config) => {
  try {
    const role  = getRole();
    const token = Token.getSync(`${role}-token`);

    // eslint-disable-next-line no-param-reassign
    config.headers.common.Authorization = `Bearer ${token}`;
  } catch (err) {
    console.log('No token defined', err);
  }

  return config;
};

const api = axios.create({
  baseURL,
});

api.interceptors.request.use(middleware);

export default api;

export function buildFormData(formData, data, parentKey) {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach(key => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    const value = data == null ? '' : data;

    formData.append(parentKey, value);
  }
}
