<template>
  <div
    id="app"
    class="app bg-cream min-h-screen"
    :class="{
      'has-user-modal': hasUserModal,
      'has-cancel-call-modal' : hasCancelCallModal,
      'has-end-call-modal' : hasEndCallModal,
      'has-schedule-modal' : openModal,
      'has-calendar-filter-modal' : hasCalendarFilter,
      'has-delete-register-modal' : hasDeleteRegisterModal,
      'has-csv-modal' : hasCSVModal
    }"
  >
    <Menu />
    <router-view />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Menu from '@/components/admin/Menu';

export default {
  components: {
    Menu,
  },
  computed: {
    ...mapState(['hasUserModal', 'hasCancelCallModal', 'hasEndCallModal', 'hasCalendarFilter', 'hasDeleteRegisterModal', 'hasCSVModal']),
    ...mapState('consultants', ['openModal']),
  },
};
</script>

<style lang="scss">
#app {
  @apply font-body font-normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.login-container {
  width: 540px;
  height: 455px;
}

.login-form-container {
  flex: none;
  width: 360px;

  .nivea-form {
    width: 360px;
  }
}

.stop-scrolling {
  overflow: hidden;
}

//
:root {
  #noty_layout__bottom {
    @apply w-full max-w-full left-0;
  }

  .noty_bar {
    @apply absolute top-auto bottom-0 inset-x-0;
  }

  .noty_body {
    @apply flex items-center py-6 px-4 text-white;

    &:before {
      content: '🎉';
      @apply flex items-center justify-center text-2xl w-11 h-11 rounded-full;
    }
  }

  .noty_type__success {
    @apply bg-success;

    .noty_body:before {
      content: '🎉';
    }
  }

  .noty_type__info {
    background-color: #ebf5ff;

    .noty_body {
      color: #3a6ee0;
    }
  }

  .noty_type__error {
    @apply bg-danger;

    .noty_body:before {
      content: '😥';
    }
  }

  .noty_has_timeout {
    .noty_progressbar {
      @apply hidden;
    }
  }
}

.admin-page {
  width: calc(100% - 245px);
}

.admin-data-table {
  text-align: left;
  border-bottom: 1px solid rgba(246, 155, 139, 0.24);
  box-shadow: 0 2px 20px rgba(0, 19, 111, 0.04);
  //width: 880px;
  max-width: 100%;

  .v-data-table-header {
    th.text-start {
      font-weight: 400;
      border-bottom: 2px solid rgba(81, 103, 207, 0.1)!important;

      &.active {
        span {
          background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.756 5.127a1 1 0 01-1.512 0L1.237 1.655A1 1 0 011.993 0h6.014a1 1 0 01.756 1.655L5.756 5.127z' fill='%235167CF'/%3E%3C/svg%3E");
        }
      }

      &.active.asc {
        span {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 6'%3E%3Cpath d='M4.2.3c.4-.5 1.1-.5 1.5 0l3 3.5c.6.7.2 1.7-.7 1.7H2c-.9 0-1.3-1-.8-1.7l3-3.5z' fill='%235167cf'/%3E%3C/svg%3E");
        }
      }

      span {
        padding-right: 15px;
        background-size: 10px;
        background-repeat: no-repeat;
        background-position: 100% center;
        background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.756 5.127a1 1 0 01-1.512 0L1.237 1.655A1 1 0 011.993 0h6.014a1 1 0 01.756 1.655L5.756 5.127z' fill='%23b9c2ed'/%3E%3C/svg%3E");
      }
    }

    .mdi-arrow-up {
      display: none;
    }
  }

  .text-start {
    font-size: 16px!important;
    color: #000!important;
  }

  tbody {
    td.text-start {
      @apply font-light cursor-pointer;
      height: 68px!important;
      line-height: 1.2;
      border-bottom: thin solid rgba(81, 103, 207, 0.1)!important;
    }
  }
}

.historic-table,
.users-rating-table {
  .v-data-table-header {
    i {
      display: none;
    }
  }
}

.table-actions {
  @apply flex items-center justify-end;
}

// Input

.theme--light.v-input,
.theme--light.v-input input,
.theme--light.v-input textarea,
.theme--light.v-label {
  @apply text-blue;
}

.v-text-field .v-label--active {
  @apply text-opacity-60;
  transform: translateY(-22px) scale(.75);
}

.v-progress-linear .v-progress-linear__indeterminate .long,
.v-progress-linear .v-progress-linear__indeterminate .short {
  @apply bg-blue;
}

.theme--light.v-text-field>.v-input__control>.v-input__slot:before,
.theme--light.v-text-field:not(.v-input--has-state):hover>.v-input__control>.v-input__slot:before {
  @apply border-blue border-opacity-25;
}

.v-text-field>.v-input__control>.v-input__slot:after {
  @apply border-blue border-opacity-25 bg-blue bg-opacity-25;
}
</style>
