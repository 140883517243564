<template>
  <aside
    class="sidebar fixed top-0 bottom-0 left-0 overflow-y-scroll bg-blue py-12"
    v-if="$route.path.includes('/admin/')"
  >
    <router-link :to="{ name: 'Dashboard' }" class="block px-6">
      <logo light />
    </router-link>

    <nav>
      <ul class="sidebar-menu mt-5">
        <li class="sidebar-menu__item" v-for="link in links" :key="link.name">
          <router-link :to="{ name: link.component }" class="block py-3 px-6 text-white">{{link.title}}</router-link>
        </li>
      </ul>

      <div class="menu-user flex items-center pt-5 px-6">
        <avatar class="menu-user-avatar mr-4 bg-white bg-opacity-40" :image="user.avatar" :name="user.name" />

        <div class="text-white">
          <p>{{ user.name }}</p>
          <button @click="logout" class="text-sm underline font-light">Sair</button>
        </div>
      </div>
    </nav>
  </aside>
</template>

<script>
import Logo from '@/components/Logo';
import Avatar from '@/components/Avatar';
import { mapActions, mapState } from 'vuex';

export default {
  name: "Menu",
  components: {
    Avatar,
    Logo,
  },
  data() {
    return {
      links: [
        {
          title: 'Dashboard',
          component: 'Dashboard'
        },
        {
          title: 'Consultoras',
          component: 'Consultants'
        },
        {
          title: 'Histórico de consultas',
          component: 'Historic'
        },
        {
          title: 'Avaliações',
          component: 'UsersRating'
        },
        {
          title: 'Usuários',
          component: 'Users'
        },
        {
          title: 'Produtos',
          component: 'Products'
        },
        {
          title: 'Administradores',
          component: 'Admins'
        },
      ]
    }
  },
  computed: {
    ...mapState('admin', ['user'])
  },
  methods: {
    ...mapActions('admin', ['logout'])
  }
};
</script>

<style lang="scss">
.sidebar {
  width: 245px;
  min-height: 100vh;
}

.sidebar-menu__item {
  a {
    &:hover,
    &.router-link-active {
      background-color: rgba(255,255,255,0.2);
    }
  }
}

.menu-user {
  border-top: 1px solid rgba(255, 255, 255, 0.25);
}
</style>
