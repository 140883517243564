import Vue from 'vue';
import Vuex from 'vuex';

import verticalModal from '@/store/VerticalModal';
import admin from '@/store/admin.js';
import consultant from '@/store/consultant.js';
import consultants from '@/store/consultants';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    hasUserModal: false,
    hasEndCallModal: false,
    hasCancelCallModal: false,
    hasCalendarFilter: false,
    hasDeleteRegisterModal: false,
    hasCSVModal: false,
    inCall: false,
    hasSentForgotPasswordForm: false,

    calendarDate: '',
    userModal: {
      user: {},
      reservation: {},
    }
  },
  mutations: {
    submitForgotPassword(state) {
      state.hasSentForgotPasswordForm = true;
    },

    openUserModal(state) {
      state.hasUserModal = true;
      document.scrollingElement.classList.add('stop-scrolling');
    },
    closeUserModal(state) {
      state.hasUserModal = false;
      document.scrollingElement.classList.remove('stop-scrolling');
    },
    toggleUserModal(state) {
      state.hasUserModal = !state.hasUserModal;
      document.scrollingElement.classList.toggle('stop-scrolling');
    },

    setCalendarDate(state, date) {
      state.calendarDate = date;
    },

    setUserModalMeta(state, user) {
      state.userModal.user = user;
    },
    setReservationModalMeta(state, reservation) {
      state.userModal.reservation = reservation;
    },

    // Call
    openCall(state) {
      state.inCall = true;
    },
    closeCall(state) {
      state.inCall = false;
    },
    toggleCall(state) {
      state.inCall = !state.inCall;
    },

    // Cancel Modal
    setCancelCallModal(state, cancel) {
      state.hasCancelCallModal = cancel;
    },

    // End Call Modal
    openEndCallModal(state) {
      state.hasEndCallModal = true;
    },
    closeEndCallModal(state) {
      state.hasEndCallModal = false;
    },
    toggleEndCallModal(state) {
      state.hasEndCallModal = !state.hasEndCallModal;
    },

    // Calendar Filter Modal
    openCalendarFilter(state) {
      state.hasCalendarFilter = true;
    },
    closeCalendarFilter(state) {
      state.hasCalendarFilter = false;
    },
    toggleCalendarFilter(state) {
      state.hasCalendarFilter = !state.hasCalendarFilter;
    },

    //Delete Register Modal
    openDeleteRegisterModal(state) {
      state.hasDeleteRegisterModal = true;
    },
    closeDeleteRegisterModal(state) {
      state.hasDeleteRegisterModal = false;
    },
    toggleDeleteRegisterModal(state) {
      state.hasDeleteRegisterModal = !state.hasDeleteRegisterModal;
    },

    //Delete CSV Modal
    openCSVModal(state) {
      state.hasCSVModal = true;
    },
    closeCSVModal(state) {
      state.hasCSVModal = false;
    },
    toggleCSVModal(state) {
      state.hasCSVModal = !state.hasCSVModal;
    },
  },
  actions: {},
  modules: {
    verticalModal,
    admin,
    consultant,
    consultants
  },
});
